<template>
  <v-container
    tag="section"
    fluid
  >
    <base-material-card
      v-for="l in ligen"
      icon="fa-table"
      :title="l.name"
      color="red"
      :key="l._id"
    >
      <v-data-table
        :items="l.results"
        :headers="[
          { text: 'Platz', value: 'place', sortable: false, align: 'center' },
          { text: 'Mannschaft', value: 'name', sortable: false, align: 'left' },
          { text: 'TP', value: 'tp', sortable: false, align: 'center' },
          { text: 'Punkte', value: 'final', sortable: false, align: 'center' },
          { text: 'WKs', value: 'wk', sortable: false, align: 'center' },
        ]"
        hide-default-footer
        :items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template #item.final="{item}">
          {{ item.final | float2_0 }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { deleteQuery, useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

const query = `
  _id
  ... on StbW2023League {
    parent { ... on StbW2023 { _id name } }
    name
    order
    teams { team { _id name } }
    results {
      team { _id name }
      final
      tp
      wk
    }
  }
`

export default {
  name: 'tables',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    date: [0, 0]
  }),

  computed: {
    lname () {
      return this.EventFind?.[0]?.parent?.name || ''
    },
    ligen () {
      return this.EventFind.map(e => ({
        ...e,
        results: e.teams.map(t => {
          const result = e.results?.find(r => r.team._id === t.team._id) || {}
          return {
            _id: t.team._id,
            name: t.team.name,
            final: Math.round((result?.final || 0) * 100) / 100,
            tp: result?.tp || 0,
            wk: result?.wk || 0
          }
        }).map((r, i, arr) => ({
          ...r,
          place: arr.filter(r2 => r2.tp > r.tp || (r2.tp === r.tp && r2.final > r.final)).length + 1
        })).sort((a, b) => a.place < b.place ? -1 : 1)
      })).sort((a, b) => a.order < b.order ? -1 : 1)
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
